var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "phones-page" },
    [
      _c("masthead", {
        attrs: { title: "Phones" },
        on: { changed: _vm.searchChanged },
      }),
      _c("template", { slot: "title" }, [_vm._v("\n    Phones\n  ")]),
      !_vm.loaded
        ? _c("ct-centered-spinner", { attrs: { height: "260px" } })
        : _vm._e(),
      _c("b-row", [
        _c("div", { staticClass: "col-md-8" }, [
          _vm.loaded
            ? _c(
                "div",
                [
                  _c(
                    "b-tabs",
                    {
                      staticClass: "container",
                      attrs: { card: "", "content-class": "mt-3" },
                    },
                    [
                      _c("b-tab", { attrs: { title: "Active", active: "" } }, [
                        _vm.virtualPhones.length > 0
                          ? _c("div", [
                              _vm.loaded
                                ? _c(
                                    "div",
                                    { staticClass: "row" },
                                    _vm._l(_vm.virtualPhones, function (phone) {
                                      return _c(
                                        "div",
                                        {
                                          key: phone.id,
                                          staticClass:
                                            "col-xl-4 col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-4",
                                        },
                                        [
                                          _c("virtual-phone-card", {
                                            attrs: { "virtual-phone": phone },
                                          }),
                                        ],
                                        1
                                      )
                                    }),
                                    0
                                  )
                                : _vm._e(),
                            ])
                          : _c("div", [
                              _c("p", [
                                _vm._v("There are currently no active phones."),
                              ]),
                            ]),
                      ]),
                      _c("b-tab", { attrs: { title: "Pending Removal" } }, [
                        _vm.virtualPhonesPendingRemoval
                          ? _c(
                              "div",
                              [
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(
                                      "\n                  You have 45 days to reactivate a number before it is permanently deleted.\n                "
                                    ),
                                  ]),
                                ]),
                                _c("virtual-phone-pending-removal-cards", {
                                  attrs: {
                                    "virtual-phones-pending-removal":
                                      _vm.virtualPhonesPendingRemoval,
                                    "fetch-phones": _vm.fetchPhones,
                                  },
                                }),
                              ],
                              1
                            )
                          : _c("div", [
                              _c("p", [
                                _vm._v(
                                  "There are no phones currently slotted for removal."
                                ),
                              ]),
                            ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
        _c("div", { staticClass: "col-md-4" }, [
          _c("div", { staticClass: "container mobile-access" }, [
            _c("div", { staticClass: "col-md-12 card shadow-sm" }, [
              _c("div", { staticClass: "card-body text-center p-2" }, [
                _c("h5", { staticClass: "card-title mb-3" }, [
                  _vm._v("Enable Mobile Access"),
                ]),
                _c("h3", { staticClass: "go-mobile" }, [
                  _c(
                    "a",
                    { attrs: { href: "#/account/mobile-access" } },
                    [
                      _c("fa-icon", {
                        staticClass: "px-2",
                        style: { color: "#009bbf" },
                        attrs: { icon: "check-circle" },
                      }),
                      _vm._v("\n                Go Mobile!\n              "),
                    ],
                    1
                  ),
                ]),
                _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-info d-flex align-items-center justify-content-center px-3 py-2 mx-auto my-5",
                    attrs: { "aria-label": "get QR codes to download app" },
                    on: {
                      click: function ($event) {
                        return _vm.navigateToMobileAccess()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n              Download the mobile app\n            "
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("capture-odd-data"),
      _c("check-virtual-phone-suspension"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }