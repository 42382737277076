<template>
  <b-container class="phones-page">
    <masthead
      title="Phones"
      @changed="searchChanged"
    />

    <template slot="title">
      Phones
    </template>

    <ct-centered-spinner v-if="!loaded" height="260px" />
    <b-row>
      <div class="col-md-8">
        <div v-if="loaded">
          <b-tabs card class="container" content-class="mt-3">
            <b-tab title="Active" active>
              <div v-if="virtualPhones.length > 0">
                <div v-if="loaded" class="row">
                  <div
                    v-for="phone in virtualPhones"
                    :key="phone.id"
                    class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-4"
                  >
                    <virtual-phone-card :virtual-phone="phone" />
                  </div>
                </div>
              </div>
              <div v-else>
                <p>There are currently no active phones.</p>
              </div>
            </b-tab>
            <b-tab title="Pending Removal">
              <div v-if="virtualPhonesPendingRemoval">
                <p>
                  <strong>
                    You have 45 days to reactivate a number before it is permanently deleted.
                  </strong>
                </p>
                <virtual-phone-pending-removal-cards
                  :virtual-phones-pending-removal="virtualPhonesPendingRemoval"
                  :fetch-phones="fetchPhones"
                />
              </div>
              <div v-else>
                <p>There are no phones currently slotted for removal.</p>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
      <div class="col-md-4">
        <div class="container mobile-access">
          <div class="col-md-12 card shadow-sm">
            <div class="card-body text-center p-2">
              <h5 class="card-title mb-3">Enable Mobile Access</h5>
              <h3 class="go-mobile">
                <a href="#/account/mobile-access">
                  <fa-icon
                    icon="check-circle"
                    class="px-2"
                    :style="{color: '#009bbf'}"
                  />
                  Go Mobile!
                </a>
              </h3>
              <button
                class="btn btn-info d-flex align-items-center justify-content-center px-3 py-2 mx-auto my-5"
                aria-label="get QR codes to download app"
                @click="navigateToMobileAccess()"
              >
                Download the mobile app
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-row>
    <capture-odd-data />
    <check-virtual-phone-suspension />
  </b-container>
</template>

<script>

import Masthead from '@/components/shared/Masthead'
import VirtualPhoneCard from '@/components/VirtualPhones/VirtualPhoneCard.vue'
import VirtualPhonePendingRemovalCards from '@/components/VirtualPhones/VirtualPhonePendingRemovalCards.vue'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner.vue'
import { mapActions, mapGetters } from 'vuex'
import CaptureOddData from '@/components/CaptureOddData'
import CheckVirtualPhoneSuspension from '@/components/VirtualPhones/CheckVirtualPhoneSuspension.vue'

export default {
  name: 'Phones',
  components: { CaptureOddData, CtCenteredSpinner, VirtualPhoneCard, Masthead, VirtualPhonePendingRemovalCards, CheckVirtualPhoneSuspension },
  data () {
    return {
      query: null,
      loaded: false,
    }
  },
  computed: {
    ...mapGetters('virtualPhones',
      [
        'virtualPhonesMatchingFilter',
        'virtualPhonesPendingRemoval',
        'suspendedPhones',
        'virtualPhones',
        'virtualPhonesFinished',
        'suspendedPhonesFinished',
      ]
    ),

    virtualPhones() {
      return this.virtualPhonesMatchingFilter(this.query)
    },
  },
  async mounted () {
    await this.fetchPhones()
  },
  methods: {
    ...mapActions('virtualPhones', ['loadVirtualPhones', 'loadSuspendedPhones', 'setVirtualPhonesPendingRemoval']),

    searchChanged (searchObject) {
      this.query = searchObject.query
    },
    async fetchPhones () {
      this.loaded = false
      if (!this.virtualPhonesFinished) {
        await this.loadVirtualPhones()
      } else if (!this.suspendedPhonesFinished) {
        await this.loadSuspendedPhones()
      }

      await this.setVirtualPhonesPendingRemoval()
      this.loaded = true
    },
    navigateToMobileAccess() {
      this.$router.push({ path: 'account/mobile-access' })
    },
  },
}
</script>

<style lang="scss" scoped>
  h3.go-mobile a {
    color: inherit;
    text-decoration: none;
    font-weight: inherit;
    font-size: inherit;
  }
</style>
