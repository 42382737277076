var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "reactivateServiceWindow",
      attrs: {
        id: "reactivate-service-window",
        title: "Confirm Service Reactivation",
        size: "xl",
        "hide-footer": "",
      },
      on: { hidden: _vm.resetModal },
    },
    [
      _vm.loaded
        ? _vm._t("body", function () {
            return [
              _c("p", [
                _vm._v(
                  "Please select the payment method you would like to use to make your payment today, " +
                    _vm._s(_vm.dateToday) +
                    "."
                ),
              ]),
              _c("payment-method-options", {
                attrs: { "payment-method-errors": _vm.paymentMethodErrors },
              }),
              _c("p", [
                _c("i", [
                  _vm._v(
                    "If you would like to use a different payment method, please add the payment method to your Payment Methods under Account Settings first."
                  ),
                ]),
              ]),
              _c("p", [
                _vm._v(
                  "I agree to reactivate my Phone Service for the number "
                ),
                _c("b", [_vm._v(_vm._s(_vm.virtualPhone.number))]),
                _vm._v(
                  " for $" +
                    _vm._s(_vm.currentProduct.price) +
                    " per " +
                    _vm._s(_vm.pricePeriod) +
                    "."
                ),
              ]),
              _c(
                "b-button",
                {
                  staticClass: "reactivate-button",
                  attrs: {
                    type: "submit",
                    variant: "primary",
                    disabled: !_vm.submitButtonEnabled,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.onSubmit()
                    },
                  },
                },
                [_vm._v("\n      Reactivate\n    ")]
              ),
            ]
          })
        : _c(
            "div",
            { staticClass: "d-flex flex-row justify-content-center" },
            [_c("ct-centered-spinner", { attrs: { label: "Loading..." } })],
            1
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }